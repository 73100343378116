/* Video Reels Section */
.video-reels {
  padding: 40px 20px;
  text-align: center;
  background-color: var(--accent-color); /* Light cream color */
  overflow: hidden;
}

/* Title Styles */
.video-reels-title {
  font-size: 1.75rem; /* Use relative units for better scaling */
  font-weight: bold;
  color: var(--dark-color); /* Rich Brown for strong title visibility */
  margin-bottom: 20px;
}

.video-reels-title a {
  color: var(--secondary-color); /* Soft Blue for link color */
  text-decoration: none;
  border-bottom: 2px solid var(--secondary-color); /* Matching border to text color */
  transition: color 0.3s, border-color 0.3s;
}

.video-reels-title a:hover {
  color: var(--primary-color); /* Deep Navy Blue for hover effect */
  border-color: var(--primary-color);
}

/* Video Container */
.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Carousel */
.carousel {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Phone Frame Styling */
.phone-frame {
  background: url("./assets/phone-frame.png") no-repeat center center;
  background-size: contain;
  width: 200px;
  /* width: fit-content; */
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s;
}

.phone-frame.active {
  transform: scale(1.1); /* Slightly larger scale for active video */
}

/* Video Styles */
.video {
  width: 90%;
  height: 70%;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Video Description */
.video-description {
  margin-top: 10px;
  font-size: 1rem; /* Adjusted for consistency */
  color: var(--dark-color);
}

/* Navigation Buttons */
.nav-button {
  background-color: var(--secondary-color);
  color: var(--accent-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: var(--primary-color);
}

/* Know More Link */
.know-more {
  margin-top: 20px;
}

.know-more a {
  font-size: 1.125rem;
  color: var(--secondary-color);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}

.know-more a:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .video-container {
    flex-direction: column;
    gap: 15px;
  }

  .phone-frame {
    width: 180px;
    height: 360px; /* Adjust frame size for tablets */
  }

  .video {
    height: 65%;
  }

  .nav-button {
    padding: 8px;
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .video-reels {
    padding: 30px 15px;
  }

  .video-reels-title {
    font-size: 1.5rem; /* Adjust title size for mobile devices */
  }

  .phone-frame {
    width: 160px;
    height: 320px;
  }

  .video {
    height: 60%;
  }

  .nav-button {
    padding: 6px;
    font-size: 1.3rem; /* Adjust button size for mobile */
  }

  .know-more a {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .video-reels {
    padding: 20px 10px; /* Reduce padding for smaller devices */
  }

  .phone-frame {
    width: 140px;
    height: 280px;
  }

  .video {
    height: 55%;
  }

  .nav-button {
    padding: 5px;
    font-size: 1.2rem;
  }

  .know-more a {
    font-size: 0.9rem;
  }
}
